import React, {useState} from 'react'
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';

import PageTransition from '../components/PageTransition';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Textarea } from '../components/ui/textarea';


import { FaPhoneAlt, FaEnvelope, FaMapMarkedAlt } from 'react-icons/fa';

const info = [
  {
    icon: <FaPhoneAlt />,
    title: 'Phone',
    description: '+971 556684014',
  },
  {
    icon: <FaEnvelope />,
    title: 'Email',
    description: 'musamamian@gmail.com',
  },
  {
    icon: <FaMapMarkedAlt />,
    title: 'Residence',
    description: 'Dubai, UAE',
  }
]

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [isErroMessage, setIsErrorMessage] =useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsErrorMessage(false);
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsErrorMessage(true);
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );
    
    // Clears the form after sending the email
    e.target.reset();
  };

return (
    <>
      <PageTransition />
      <motion.div
        initial={{opacity:0}} 
        animate={{
          opacity: 1,
          transition: {delay: 1.4, duration: 0.4, ease: 'easeIn'}
        }}
        className='py-6'
      >
        <section>
          <div className="container mx-auto">
            <div className='flex flex-col xl:flex-row gap-[30px]'>
              <div className='xl:w-[54%] order-2 xl:order-none'>
                <form onSubmit={sendEmail} className='flex flex-col gap-6 p-10 bg-[#27272c] rounded-xl'> 
                    <h3 className='text-4xl text-accent'>Let's work together!</h3>
                    <p className='text-white/60'>Get the ball rolling and build phenomenal apps together. Reach out for inquiries and collaboration.</p>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                      <Input name="first_name" type='firstname' placeholder='First name'/>
                      <Input name="last_name" type='lastname' placeholder='Last name'/>
                      <Input name="email" type='email' placeholder='Email address'/>
                      <Input name="phone" type='phone' placeholder='Phone number'/>
                    </div>
                    <Textarea name="message" className='h-[200px]' placeholder='Type your message here.' />
                    <Button size='md' className='max-w-40'>
                      <input type="submit" value="Send message" disabled={isSubmitting} />
                    </Button>
                    {stateMessage && isErroMessage ? <p className='text-rose-700'>{stateMessage}</p> : <p className='text-accent'>{stateMessage}</p>}
                </form>
              </div>
              <div className='flex-1 flex items-center xl:justify-end order-1 xl:order-none mb-8 xl:mb-0'>
                <ul className='flex flex-col gap-10'>
                  {info.map((item, index)=> {
                    return (
                      <li key={index} className='flex items-center gap-6'>
                        <div className='w-[52px] h-[52px] xl:w-[72px] xl:h-[72px] bg-[#27272c] text-accent rounded-md flex items-center justify-center'>
                          <div className='text-[28px]'>
                            {item.icon}
                          </div>
                        </div>
                        <div className='flex-1'>
                          <p className='text-white/60'>
                            {item.title}
                          </p>
                          <h3 className='text-xl'>{item.description}</h3>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </>
  )
}

export default Contact