import React from 'react';
import { BsArrowDownRight } from 'react-icons/bs';
import { motion } from 'framer-motion';

import PageTransition from '../components/PageTransition';

const services =[
  {
    num: "01",
    title: "React",
    description: "Providing web app development using React",
    href:"https://www.upwork.com/freelancers/~0157ae5ab7d6369b24?mp_source=share"
  },
  {
    num: "02",
    title: "Design to HTML",
    description: "Providing design to html conversion",
    href:"https://www.upwork.com/freelancers/~0157ae5ab7d6369b24?mp_source=share"
  },
  {
    num: "03",
    title: "WordPress sites",
    description: "Providing website development for businesses using WordPress",
    href:"https://www.upwork.com/freelancers/~0157ae5ab7d6369b24?mp_source=share"
  },
  {
    num: "04",
    title: "SFCC: Frontend",
    description: "Providing frontend development services in Salesforce commerce cloud SFRA",
    href:"https://www.upwork.com/freelancers/~0157ae5ab7d6369b24?mp_source=share"
  },

]

const Services = () => {
  return (
    <>
      <PageTransition />
      <section className='min-h-[80vh] flex flex-col justify-center py-12 xl:py-0'>
        <div className="container mx-auto">
          <motion.div
          initial={{opacity: 0}}
          animate={{
            opacity:1,
            transition: {delay:1.4, duration: 0.4, ease:'easeIn'},
          }}
          className='grid grid-cols-1 md:grid-cols-2 gap-[60px]'
          >
            {services.map((service, index)=> {
              return <div className='flex-1 flex flex-col justify-center gap-6 group' key={index}>
                  <div className='w-full flex justify-between items-center'>
                      <p className='text-5xl font-extrabold text-outline text-transparent group-hover:text-outline-hover transition-all duration-500'>
                        {service.num}
                      </p>
                      <a href={service.href} className='w-[70px] h-[70px] rounded-full bg-white
                      group-hover:bg-accent transitoion-all duration-500 flex justify-center items-center 
                      hover:-rotate-45' target='_blank' rel='noreferrer'>
                        <BsArrowDownRight className='text-primary text-3xl'/>
                      </a>
                  </div>
                  <h2 className='text-4xl font-bold leading-none text-white group-hover:text-accent transitoion-all duration-500'>{service.title}</h2>
                  <p className='text-white/60'>{service.description}</p>
                  <div className='border-b border-white/20 w-full'></div>
              </div>
            })}
          </motion.div>
        </div>
      </section>
    </>
  )
}

export default Services