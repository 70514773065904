import React from 'react'
import { NavLink } from "react-router-dom";

const Nav = () => {
  return (
    <nav>
      <ul className='flex items-center justify-between gap-8'>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
            }
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/services"
            className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
            }
          >
            Services
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/cv"
            className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
            }
          >
            CV
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/portfolio"
            className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
            }
          >
            Portfolio
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav