import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Animation from './Animation';

const PageTransition = () => {
  return (
    <>
     <AnimatePresence mode="wait">
        <div className='bottom-0 fixed top-0 left-0 right-0 pointer-events-none z-40 flex'>
            <Animation/>
        </div>
    </AnimatePresence>
    <motion.div
    initial={{opacity: 1}}
    animate={{
        opacity: 0,
        transition: {delay:1, duration: 0.6, ease: 'easeInOut'},
    }}
    className='h-screen w-screen fixed bg-primary top-0 pointer-events-none'
  />
  </>
  );
}

export default PageTransition;