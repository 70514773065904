import React from 'react'
import { FaHtml5, FaCss3, FaReact, FaJs, FaSass, FaFigma, FaWordpress, FaSalesforce } from 'react-icons/fa';
import { SiTailwindcss,  SiShadcnui, SiRedux, SiJquery  } from 'react-icons/si';

import PageTransition from '../components/PageTransition';
import { 
  Tabs, 
  TabsContent, 
  TabsList, 
  TabsTrigger } from '../components/ui/tabs';

import { 
  Tooltip, 
  TooltipContent, 
  TooltipProvider, 
  TooltipTrigger } from '../components/ui/tooltip';

import { ScrollArea } from '../components/ui/scroll-area';
import { motion } from 'framer-motion';

const about = {
  title: 'About me',
  description: 
    'A front-end developer, significantly enhancing global clients user experiences and managing large-scale tech projects. With a strong background in stakeholder management and multi-platform UI development, I believe I am a valuable asset for innovative digital projects.',
  info: [
    {
      fieldName: 'Name',
      fieldValue: 'Usama Mian'
    },
    {
      fieldName: 'Experience',
      fieldValue: '7+ years'
    },
    {
      fieldName: 'Phone',
      fieldValue: '+971 556684014'
    },
    
    {
      fieldName: 'Email',
      fieldValue: 'musamaiqbalmian@gmail.com'
    },
    {
      fieldName: 'LinkedIn',
      fieldValue: 'm-usamamian'
    },
    {
      fieldName: 'GitHub',
      fieldValue: 'musamamianfed'
    },
  ]
};

const experience = {
  icon: '/assets/resume/badge.svg',
  title: 'Experience',
  description: 'A proven track record of enhancing user experiences for high-profile clients across the globe. Expertise in stakeholder management, coupled with technical skills, positions me well to innovate and drive successful digital projects.',
  items: [
    {
      company: 'ECOMNEST',
      country: 'Remote',
      position: 'Senior Frontend developer',
      duration: 'Nov 2021 - Present'
    },
    {
      company: 'i2c inc.',
      country: 'Pakistan',
      position: 'Senior Frontend developer',
      duration: 'May 2021 - Nov 2021'
    },
    {
      company: 'Systems Limited',
      country: 'Pakistan',
      position: 'Frontend developer',
      duration: 'Dec 2016 - May 2021'
    },
  ]
};

const education = {
  icon: '/assets/resume/cap.svg',
  title: 'Education',
  description: 'Formed a strong foundation for technical and entrepreneurial endeavors while acquiring my bachelors degree.',
  items: [
    {
      university: 'COMSATS University Islamabad',
      degree: 'Bachelor of Software Engineering',
      duration: 'Sep 2012 - June 2016',
    },
  ]
};

const skills = {
  title: 'Skills',
  description: 'Technical skills acquired via collaboration and continous learning over 7+ years',
  skillList : [
    {
      icon: <FaHtml5 />,
      name: 'html 5'
    },
    {
      icon: <FaCss3 />,
      name: 'Css3'
    },
    {
      icon: <FaSass />,
      name: 'Sass/Scss'
    },
    {
      icon: <FaJs />,
      name: 'Js ES6'
    },
    {
      icon: <SiJquery />,
      name: 'jQuery'
    },
    {
      icon: <FaReact />,
      name: 'React'
    },
    {
      icon: <SiRedux />,
      name: 'Redux'
    },
    {
      icon: <SiTailwindcss />,
      name: 'Tailwind CSS'
    },
    {
      icon: <SiShadcnui />,
      name: 'Shadcn/ui'
    },
    {
      icon: <FaFigma />,
      name: 'Figma'
    },
    {
      icon: <FaSalesforce />,
      name: 'Salesforce Commerce Cloud SFRA'
    },
    {
      icon: <FaWordpress />,
      name: 'WordPress'
    },
  ]
};


const Cv = () => {
  return (
    <>
      <PageTransition />
      <section>
          <motion.div 
            initial={{opacity: 0}}
            animate={{
              opacity:1,
              transition: {
                delay: 1.4,
                duration: 0.4,
                ease: 'easeIn'
              }
            }}
            className='min-h-[80vh] flex items-center justify-center py-8 xl:py-0'
          >
            <div className="container mx-auto">
              <Tabs 
                defaultValue='experience'
                className='flex flex-col xl:flex-row gap-[60px]'
                >
                <TabsList 
                  className='flex flex-col w-full max-w-[380px] mx-auto xl:mx-0 gap-6'
                >
                  <TabsTrigger value='experience'>Experience</TabsTrigger>
                  <TabsTrigger value='education'>Education</TabsTrigger>
                  <TabsTrigger value='skills'>Skills</TabsTrigger>
                  <TabsTrigger value='about'>About me</TabsTrigger>
                </TabsList>
                <div className='min-h-[70vh] w-full'>
                  <TabsContent value='experience' className='w-full'>
                    <div className='flex flex-col gap-[30px] text-center xl:text-left'>
                      <h3 className='text-4xl font-bold'>{experience.title}</h3>
                      <p className='max-w-[600px] text-white/60 mx-auto xl:mx-0'>{experience.description}</p>
                      <ScrollArea className='h-[480px]'>
                        <ul className='grid grid-cols-1 lg:grid-cols-2 gap-[30px]'>
                          {experience.items.map((item, index)=> {
                            return (
                              <li 
                                className='bg-[#232329] h-[184px] py-6 px-10 rounded-xl flex flex-col justify-center items-center lg:items-start gap-1' 
                                key={index}
                              >
                                  <span className='text-accent'>{item.duration}</span>
                                  <h3 className='text-xl max-w-[260px] min-h-[60px]
                                  text-center lg:text-left'>{item.position}</h3>
                                  <div className='flex items-center gap-3'>
                                    <span className='w-[6px] h-[6px] rounded-full bg-accent'> </span>
                                    <p className='text-white/60'>{item.company}, <span className='italic'>{item.country}</span></p>
                                  </div>
                              </li>
                            )
                          })}
                        </ul>
                      </ScrollArea>
                    </div>
                  </TabsContent>
                  <TabsContent value='education' className='w-full'>
                    <div className='flex flex-col gap-[30px] text-center xl:text-left'>
                      <h3 className='text-4xl font-bold'>{education.title}</h3>
                      <p className='max-w-[600px] text-white/60 mx-auto xl:mx-0'>{education.description}</p>
                      <ScrollArea className='h-[480px]'>
                        <ul className='grid grid-cols-1 lg:grid-cols-2 gap-[30px]'>
                          {education.items.map((item, index)=> {
                            return (
                              <li 
                                className='bg-[#232329] h-[184px] py-6 px-10 rounded-xl flex flex-col justify-center items-center lg:items-start gap-1' 
                                key={index}
                              >
                                  <span className='text-accent'>{item.duration}</span>
                                  <h3 className='text-xl max-w-[260px] min-h-[60px]
                                  text-center lg:text-left'>{item.degree}</h3>
                                  <div className='flex items-center gap-3'>
                                    <span className='w-[6px] h-[6px] rounded-full bg-accent'> </span>
                                    <p className='text-white/60'>{item.university}</p>
                                  </div>
                              </li>
                            )
                          })}
                        </ul>
                      </ScrollArea>
                    </div>
                  </TabsContent>
                  <TabsContent value='skills' className='w-full h-full'>
                    <div className='flex flex-col gap-[30px]'>
                        <div className='flex flex-col gap-[30px] text-center xl:text-left'>
                          <h3 className='text-4xl font-bold'>{skills.title}</h3>
                          <p className='max-w-[600px] text-white/60 mx-auto xl:mx-0'>{skills.description}</p>
                        </div>
                        <ul className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 xl:gap-[30px]'>
                          {skills.skillList.map((skill, index)=> {
                              return (
                                <li key={index}>
                                  <TooltipProvider delayDuration={100}>
                                    <Tooltip>
                                      <TooltipTrigger className='w-full h-[150px] bg-[#232329] rounded-xl flex justify-center items-center group'>
                                        <div className='text-6xl group-hover:text-accent transition-all duration-300'>{skill.icon}</div>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p className='capitalize'>{skill.name}</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </li>
                              )
                          })}
                        </ul>
                    </div>
                  </TabsContent>
                  <TabsContent value='about' className='w-full text-center xl:text-left'>
                    <div className='flex flex-col gap-[30px]'>
                      <h3 className='text-4xl font-bold'>
                        {about.title}
                      </h3>
                      <p className='max-w-[600px] text-white/60 mx-auto xl:mx-0'>{about.description}</p>
                      <ul className='grid grid-cols-1 xl:grid-cols-2 gap-y-6 gap-x-2 max-w-[640px] mx-auto xl:mx-0'>
                        {about.info.map((item, index) => {
                          return (
                            <li 
                              key={index}
                              className='flex items-center justify-center xl:justify-start gap-4'
                            >
                              <span className='text-white/60'>{item.fieldName}</span>
                              <span className='text-xl'>{item.fieldValue}</span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </TabsContent>
                </div>
              </Tabs>
            </div>

          </motion.div>
      </section>
    </>
  )
}

export default Cv