import React from 'react';
import PageTransition from '../components/PageTransition';

const ErrorPage = () => {
  return (
    <>
      <PageTransition />
      <section className='h-full'>
      <div className="container mx-auto h-full flex items-center justify-center pt-32">
        <h2 className='h3 mb-6 text-accent text-center'>
          Oops! something went wrong. <br /> 
          Page not found.
        </h2>
      </div>
      </section>
    </>
  )
}

export default ErrorPage