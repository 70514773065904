import React from 'react';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';


const socials =[
    {icon: <FaGithub/>, path:'https://github.com/musamamianfed'},
    {icon: <FaLinkedinIn/>, path:'https://www.linkedin.com/in/m-usamamian/'},
]
const Socials = ({containerStyles, iconStyles}) => {
  return <div className={containerStyles}>
    {socials.map((item, index)=> {
        return <a key={index} href={item.path} className={iconStyles} target='_blank' rel='noreferrer'>
            {item.icon}
        </a>
    })}
    </div>
}

export default Socials