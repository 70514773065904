import React from 'react';
import { Sheet, SheetTrigger, SheetContent } from './ui/sheet';
import { Button } from './ui/button';
import { NavLink } from 'react-router-dom';
import { CiMenuFries } from 'react-icons/ci';

const MobileNav = () => {
  return (
    <Sheet>
        <SheetTrigger className='flex justify-center items-center'>
            <CiMenuFries className='text-[32px] text-accent' />
        </SheetTrigger>
        <SheetContent>
        <div className='container mx-auto'>
          <NavLink to="/" >
            <h1 className='my-24 text-3xl font-semibold text-center'>
              Usama<span className='text-accent'>.</span>
            </h1>
          </NavLink>
            <nav>
                <ul className='flex flex-col gap-8 items-center justify-center'>
                    <li>
                    <NavLink
                        to="/"
                        className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
                        }
                    >
                        Home
                    </NavLink>
                    </li>
                    <li>
                    <NavLink
                        to="/services"
                        className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
                        }
                    >
                        Services
                    </NavLink>
                    </li>
                    <li>
                    <NavLink
                        to="/cv"
                        className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
                        }
                    >
                        CV
                    </NavLink>
                    </li>
                    <li>
                    <NavLink
                        to="/portfolio"
                        className={({ isActive }) => isActive ? "text-accent border-b-2 border-accent" : "text-white hover:text-accent"
                        }
                    >
                        Portfolio
                    </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact">
                            <Button className='bg-accent text-primary text-md font-bold'>Let's connect!</Button>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
        </SheetContent>
    </Sheet>
  )
}

export default MobileNav;