import React, {useState} from 'react';
import { motion } from 'framer-motion';

import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

import { BsArrowUpRight, BsGithub } from 'react-icons/bs';

import PortfolioSliderBtns from '../components/PortfolioSliderBtns';

import { 
  Tooltip, 
  TooltipContent, 
  TooltipProvider, 
  TooltipTrigger } from '../components/ui/tooltip';

import PageTransition from '../components/PageTransition';

const projects = [
  {
    num: '01',
    category: 'e-Commerce',
    title: 'Popsockets',
    description: 'A leading US-based global digital-lifestyle company, known for its iconic phone grips, with over 245 million sold in 75 countries',
    stack : [
      {name : 'Html 5'}, {name : 'Scss'}, {name : 'Javascript ES6'}, {name : 'jQuery'}, {name : 'Salesforce Commerce Cloud'}, 
    ],
    image: './assets/work/thumb1.png',
    live: 'https://www.popsockets.com/',
    github: ''
  },
  {
    num: '02',
    category: 'Entertainment',
    title: 'Netflix GPT',
    description: 'A Netflix clone app built using react and redux that recommends movies using Chat gpt 3.5',
    stack : [
      {name : 'Html 5'}, {name : 'Tailwind CSS'}, {name : 'Javascript ES6'},  {name : 'React'}, {name : 'Redux'}, 
    ],
    image: './assets/work/thumb4.png',
    live: '',
    github: 'https://github.com/musamamianfed/netflixGPT/blob/main/netflix-gpt/README.md'
  },
  {
    num: '03',
    category: 'e-Commerce',
    title: 'Horrizon Hobby',
    description: 'A leading US-based radio control products company amplifying global reach, with products sold in 50 countries',
    stack : [
      {name : 'Html 5'}, {name : 'Scss'}, {name : 'Javascript ES6'}, {name : 'jQuery'}, {name : 'Salesforce Commerce Cloud'}, 
    ],
    image: './assets/work/thumb2.png',
    live: 'https://www.horizonhobby.com/',
    github: ''
  },
  {
    num: '04',
    category: 'e-Commerce',
    title: 'Tower Hobbies',
    description: 'A leading US-based radio control products company amplifying global reach, with products sold in 50 countries',
    stack : [
      {name : 'Html 5'}, {name : 'Scss'}, {name : 'Javascript ES6'}, {name : 'jQuery'}, {name : 'Salesforce Commerce Cloud'}, 
    ],
    image: './assets/work/thumb3.png',
    live: 'https://www.towerhobbies.com/',
    github: ''
  },
]

const Portfolio = () => {

  const [project, setProject] = useState(projects[0]);

  const handleSlideChange = (swiper) => {

    const currentIndex = swiper.activeIndex;

    setProject(projects[currentIndex]);
  }

  return (
    <>
      <PageTransition />
      <motion.div 
        initial={{opacity:0}} 
        animate={{
          opacity: 1,
          transition: {delay: 1.4, duration: 0.4, ease: 'easeIn'}
        }}
        className='min-h-[80vh] flex flex-col justify-center py-8 xl:px-0'>
        <div className="container mx-auto">
         <div className='flex flex-col xl:flex-row xl:gap-[30px]'>
          <div className='w-full xl:w-1/2 xl:h-[468px] flex flex-col xl:justiy-between order-2 xl:order-none'>
                <div className='flex flex-col gap-[30px] h-[50%]'>
                  <p className='text-8xl leading-none font-extrabold text-transparent text-outline'>{project.num}</p>
                  <p className='text-xl text-accent'>{project.category}</p>
                  <h2 className='text-[42px] font-bold leading-none text-white group-hover:text-accent transition-all duration-500 capitalize'>{project.title}</h2>
                  <p className='text-white/60'>{project.description}</p>
                  <ul>
                    {project.stack.map((item, index)=> {
                      return (
                        <li 
                          key={index} 
                          className='text-xl text-accent'
                        >
                          {item.name}
                          {index !== project.stack.length - 1 && ','}
                        </li>
                      )
                    })}
                  </ul>
                  <div className='border border-white/20'></div>
                  <div className='flex items-center gap-4'>
                  {project.live.length > 1 && 
                    <a href={project.live} target='_blank' rel='noreferrer'>
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger className='w-[70px] h-[70px] rounded-full bg-white/5 flex justify-center items-center group'>
                          <BsArrowUpRight className='text-white text-3xl group-hover:text-accent'/>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Live Project</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </a>
                  }
                  {project.github.length > 1 && 
                    <a href={project.github} target='_blank' rel='noreferrer'>
                      <TooltipProvider delayDuration={100}>
                        <Tooltip>
                          <TooltipTrigger className='w-[70px] h-[70px] rounded-full bg-white/5 flex justify-center items-center group'>
                            <BsGithub className='text-white text-3xl group-hover:text-accent'/>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>GitHub repository</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </a>
                  }
                  </div>
                </div>
            </div>
            <div className='w-full xl:w-1/2'>
                <Swiper 
                  spaceBetween={30} 
                  slidesPerView={1}
                  className='xl:h-[512px] mb-12'
                  onSlideChange={handleSlideChange}
                  >
                  {projects.map((project, index ) => {
                    return <SwiperSlide 
                      key={index}
                      className='w-full'
                    >
                      <div className='h-[270px] md:h-[460px] relative group flex justify-center items-center bg-pink-50/20'>
                        <div className='absolute top-0 bottom-0 w-full h-full bg-black/10 z-10'></div>
                        <div className='relative w-full h-full'>
                          <img 
                            src={project.image}
                            alt="project images" 
                            className='object-cover w-full'
                          />
                        </div>
                      </div>
                    </SwiperSlide>;
                  })}
                  <PortfolioSliderBtns 
                    containerStyles='flex gap-2 absolute right-0 bottom-[calc(50%_-_22px)] xl:bottom-0 z-20 w-full justify-between xl:w-max xl:justify-none'
                    btnStyles='bg-accent hover:bg-accent-hover text-primary text-[22px] w-[44px] h-[44px] flex justify-center items-center transition-all'
                    />
                </Swiper>
            </div>
         </div>
        </div>
      </motion.div>
    </>
  )
}

export default Portfolio