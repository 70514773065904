import React from 'react';
import { FiDownload } from 'react-icons/fi';

import { Button } from '../components/ui/button';
import PageTransition from '../components/PageTransition';
import Socials from '../components/Socials';
import Photo from '../components/Photo';
import Stats from '../components/Stats';

const HomeInfo = () => {
  return (
    <>
      <PageTransition />
      <section className='h-full'>
        <div className="container mx-auto h-full">
          <div className='flex flex-col xl:flex-row items-center justify-between xl:pt-8 xl:pb-24'>
            <div className='text-center xl:text-left order-2 xl:order-none'>
              <span className='text-xl'>Frontend Developer</span>
              <h2 className='h1 mb-6'>
                Hello, I'm <br /> <span className='text-accent'>Usama Mian</span>
              </h2>
              <p className='max-w-[500px] mb-9 text-white/80'>Experienced front-end developer and Entrepreneur with over 7 years of expertise in crafting mobile-first front-end 
                  solutions and stake holder management.</p>
              <div className='flex flex-col xl:flex-row items-center gap-8'>
                <a href='../assets/resume/UsamaMian-FrontendDeveloper.pdf'
                  download='usama mian cv'
                  target='_blank'
                >
                  <Button 
                    variant='outline'
                    size='lg'
                    className='uppercase flex items-cente gap-2'>
                      <span>Download CV</span>
                      <FiDownload className='text-xl'/>
                  </Button>
                </a>
                <div className='mb-8 xl:mb-0'>
                    <Socials 
                      containerStyles='flex gap-6' 
                      iconStyles='w-9 h-9 border border-accent rounded-full flex justify-center items-center
                      text-accent text-base hover:bg-accent hover:text-primary
                      hover:transition-all duration-500' />
                </div>
              </div>
            </div>
            <div className='order-0 xl:order-none mb-8 xl:mb-0'>
              <Photo />
            </div>
          </div>
        </div>
      </section>
      <Stats />
    </>
  )
}

export default HomeInfo