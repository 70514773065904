import React from 'react'
import { motion } from 'framer-motion';

const Photo = () => {
  return <div className='w-full h-full relative'>
    <motion.div
        initial={{opacity: 0}}
        animate={{
            opacity:1,
            transition: {delay: 2, duration: 0.4, ease:'easeIn'}}}
    >
        <motion.div
            initial={{opacity: 0}}
            animate={{
                opacity:1,
                transition: {delay: 2.4, duration: 0.4, ease:'easeInOut'}}}
                className='w-[250px] h-[250px] xl:w-[498px] xl:h-[498px] rounded-full overflow-hidden mix-blend-lighten absolute ml-[6px]'>
                <img className='w-full' src="./assets/profile-picture.png" alt="Usama Mian's portrait" />
        </motion.div>
        <motion.svg 
            className='w-[262px] h-[262px] xl:w-[512px] xl:h-[512px]'
            fill='transparent'
            viewBox='0 0 508 508'
            xmlns='http://www.w3.org/2000/svg'>
            <motion.circle  
                cx='253' 
                cy='250' 
                r='250'
                stroke='#00ff99' 
                strokeWidth='4' 
                strokeLinecap='round'
                strokeLinejoin='round'
                initial={{strokeDasharray: '24 10 0 0'}}
                animate={{
                    strokeDasharray: ['15 120 25 25', '16 25 92 72', '4 250 22 22'],
                    rotate: [120, 360]
                }}
                transition={{
                    duration:20,
                    repeat: Infinity,
                    repeatType: 'reverse'
                }}
            />
        </motion.svg>
    </motion.div>
    </div>;
}

export default Photo