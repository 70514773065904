
import './App.css';
import React from 'react';
import {  Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Services from './pages/Services';
import Cv from './pages/Cv';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import HomeInfo from './pages/HomeInfo';
import ErrorPage from './pages/ErrorPage';


function App() {
  return (
   <main>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<HomeInfo />} />
          <Route path="services" element={<Services />} />
          <Route path="cv" element={<Cv />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
   </main>
  );
}

export default App;
