import React from 'react'

import Nav from './Nav';
import MobileNav from './MobileNav';
import { Button } from './ui/button';
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className='py-8 xl:py-12 text-white'>
        <div className='container mx-auto flex justify-between items-center'>
          <NavLink to="/" >
            <h1 className='text-4xl font-semibold'>
              Usama<span className='text-accent'>.</span>
            </h1>
          </NavLink>
            <div className='hidden xl:flex items-center gap-8'>
                <Nav />
                <NavLink to="/contact">
                  <Button className='bg-accent text-primary text-md font-bold'>Let's connect!</Button>
                </NavLink>
            </div>
            <div className="xl:hidden">
              <MobileNav />
            </div>
        </div>
    </header>
  )
}

export default Header;